/* eslint-disable */

export function setCookie(name, value, months) {
    const d = new Date();
    d.setTime(d.getTime() + (months * 1000 * 60 * 60 * 24 * 30));
    const cookie  = `${name}=${value};expires=${d.toUTCString()};path=/;domain=${YP_CONFIG.cookieDomain};secure`;
    document.cookie = cookie;
}

export function getCookie(cookieName) {
    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

export function deleteCookie(name, value = '0') {
    const expireDate = 'Thu, 01 Jan 1970 00:00:01 GMT';
    const expires = 'expires=' + expireDate;
    const cookie  = name + '=' + value + ';' + expires;
    document.cookie = cookie;
}

/**
 * Retrieve the current cookies from the doument.cookie global variable
 * @return {Object} The cookies with returned[cookieKey] = cookieValue format
 */
export function getCookies() {
    const cookies = {};
    if (!document.cookie) return cookies;
    document.cookie.replace(/ /g, '').split(';').forEach(rawCookie => {
        const [
            key,
            value,
        ] = rawCookie.split('=');
        if (!key || !value) return;
        cookies[key] = value;
    });
    return cookies;
}
