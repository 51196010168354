import { setCookie, getCookie } from '../commons/helpers/CookiesHelpers';

/**
 * Store utm search params in local storage
 */
const UTM_PARAMS = ['utm_source', 'utm_campaign', 'utm_medium'];
const PREFIX = 'yp_utm_';
const EXPIRATION_MONTHS = 1;

function saveUtm(utmParamName, utmValue) {
    setCookie(`${PREFIX}${utmParamName}`, utmValue, EXPIRATION_MONTHS);
}

function getUtm(utmParamName) {
    const utmValue = getCookie(`${PREFIX}${utmParamName}`);
    if (!utmValue) return null;
    return utmValue || null;
}

/**
 * Get saved utm params from local storage
 */
export function getSavedUtms() {
    return UTM_PARAMS.reduce((utms, utmParamName) => {
        const utmData = getUtm(utmParamName);
        if (!utmData) return utms;
        return {
            ...utms,
            [utmParamName]: utmData,
        };
    }, {});
}

/**
 * Save utm params to local storage
 */
function saveUtms() {
    const params = new URLSearchParams(window.location.search);
    UTM_PARAMS.forEach((utmParamName) => {
        const utmValue = params.get(utmParamName);
        if (!utmValue) return;
        saveUtm(utmParamName, utmValue);
    });
}

window.addEventListener('load', saveUtms);
